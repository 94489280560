<template>
  <div
    class="feedback-slider"
    :class="themeColorClass"
  >
    <UiHeader
      v-if="header"
      class="feedback-slider__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div
      v-if="itemList.length"
      class="feedback-slider__wrapper"
    >
      <UiSwiper
        :options="swiperOptions"
        class="feedback-slider__carousel"
      >
        <SwiperSlide
          v-for="item in itemList"
          :key="item.id"
          class="feedback-slider__item-slide"
        >
          <div class="feedback-slider__item">
            <div
              v-if="item.text"
              class="feedback-slider__content"
            >
              <SvgIconQuote class="feedback-slider__quote" />

              <T3HtmlParser
                :content="item.text"
                class="feedback-slider__desc"
              />
            </div>

            <UiImg
              :image="item.logo"
              class="feedback-slider__logo"
              use-image-size
            />
          </div>
        </SwiperSlide>
      </UiSwiper>

      <div
        ref="pagination"
        class="swiper-pagination"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import SvgIconQuote from '~ui/assets/icons/icon-quote.svg'
import { UiHeader, UiImg, UiSwiper } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeProductBoxesProps } from '~ui/types'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)

const pagination = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Pagination],
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 24,
    pagination: {
      el: pagination.value,
      type: 'bullets',
      clickable: true,
      bulletElement: 'button'
    }
  })
)
</script>

<style lang="scss">
.feedback-slider {
  overflow: hidden;
  padding-bottom: rem(24px);
  position: relative;

  &__wrapper > div {
    padding-bottom: rem(24px);
  }

  & .swiper-pagination {
    bottom: rem(-24px);
    inset-inline: 0;
  }

  & .swiper-pagination-bullet-active {
    &::before,
    &:hover::before {
      background-color: var(--theme__theme-color);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: rem(24px);
    border: 1px solid color(alto);
    border-radius: em(4px);
    min-height: rem(240px);
    height: 100%;
    gap: rem(24px);

    &-slide {
      width: rem(648px);
    }
  }

  &__quote {
    margin-bottom: rem(17px);
    height: 32px;
    aspect-ratio: 1;

    & path {
      fill: var(--theme__theme-color);
    }
  }

  &__title {
    white-space: nowrap;

    @include media-query(lg) {
      margin-bottom: 0;
    }
  }

  &__desc p {
    font-style: italic;
    margin: 0;
  }

  &__logo {
    display: block;
    object-fit: contain;
    object-position: left;
    height: rem(40px);
  }
}
</style>
